
import { prop, Vue } from "vue-class-component";

class Props {
  fill = prop<boolean>({
    default: false,
    type: Boolean
  });
}
export default class CargoPlane extends Vue.with(Props) {}
